import Image from 'next/image';
import Link from 'next/link';
import { useSession } from 'next-auth/react';
import { useTranslations } from 'next-intl';

import { useOriginName } from 'hooks/useQuerySearch';
import { IExploreNearby } from 'typings';

import SVGLoco from '../../public/assets/icons/loco.svg';
import AppSearchWidget from '../search/SearchWidget';
import { Badge } from '../ui/badge';

interface HeroProp {
  cover: string;
  exploreNearby: IExploreNearby[];
}

const AppHeroNew = ({ cover, exploreNearby }: HeroProp) => {
  const t = useTranslations('lobby');
  const [originName] = useOriginName();
  const session = useSession()?.data;
  const isLoggedIn = session ? true : false;

  return (
    <div className="container max-w-6xl mt-[5.375rem]">
      <div className="relative m-0 py-9 pt-28 flex justify-center items-center">
        <h1 className="whitespace-normal text-center text-3xl font-semibold font-prosto md:mt-2.5 md:text-7xl">
          {t.rich('mobileTitle', {
            color: (chunks) => (
              <div className="text-orange inline-block items-center transition-colors">
                <span className="relative group overflow-visible">
                  <SVGLoco className="w-[4.5rem] h-[4.5rem] mb-3.5 inline-block hover:rotate-6 transition-transform duration-300" />
                </span>{' '}
                {chunks}
              </div>
            ),
            br: () => <br />,
          })}
        </h1>
      </div>

      <div className="mb-12 h-[86vh] min-h-[600px] max-h-[630px] flex-row flex gap-3.5 border-2 border-opacity-15 border-slate-600 rounded-[38px] p-3.5">
        <div className="basis-3/5 relative">
          <div className="absolute z-0 top-9 mx-auto px-12 w-full flex flex-col gap-10 justify-center items-center">
            <p className="text-2xl font-semibold text-center md:text-2xl mix-blend-difference">
              {t('subtitle')}
            </p>
          </div>

          <div className="absolute z-0 bottom-6 mx-auto px-12 w-full flex flex-col gap-10 justify-center items-center">
            <div className="bottom-10 right-0 left-0 mx-auto flex flex-col items-center gap-4">
              <div className="flex flex-row justify-center items-center -space-x-3">
                <Image
                  className="rounded-full shadow-sm bg-cover border-2 border-white h-10 w-10 z-40"
                  alt="Community Image"
                  width="300"
                  src="/assets/users/martin.jpg"
                  height="300"
                />
                <Image
                  className="rounded-full shadow-sm bg-cover border-2 border-white h-10 w-10 z-30"
                  alt="Community Image"
                  width="300"
                  src="/assets/users/julie.jpg"
                  height="300"
                />
                <Image
                  className="rounded-full shadow-sm bg-cover border-2 border-white h-10 w-10 z-20"
                  alt="Community Image"
                  width="300"
                  src="/assets/users/sebastien.jpg"
                  height="300"
                />
                <Image
                  className="rounded-full shadow-sm bg-cover border-2 border-white h-10 w-10"
                  alt="Community Image"
                  width="300"
                  src="/assets/users/quentin_francois.jpg"
                  height="300"
                />
                <p className="font-semibold pl-3 text-secondary align-middle">+5000</p>
              </div>
              <Badge variant="secondary">{t('community.title')}</Badge>
            </div>
          </div>

          <div className="absolute z-0 top-24 mx-auto px-28 w-full flex flex-col gap-10 justify-center items-center">
            <AppSearchWidget exploreNearby={exploreNearby || []} />
          </div>

          <Image
            src={cover}
            alt="hero"
            placeholder="blur"
            blurDataURL={cover}
            quality={60}
            priority
            width={500}
            height={500}
            className="rounded-3xl h-full w-full shadow-arround object-cover"
            sizes={'(max-width: 768px) 100vw, (max-width: 1024px) 100vw, 50vw'}
          />
        </div>

        <Link
          href={`/search?origin=${originName}`}
          title="Voir la carte"
          className="relative group rounded-3xl shadow-arround basis-2/5 hover:scale-[98%] opacity-50 hover:opacity-100 transform duration-500 cursor-pointer"
        >
          <div className="absolute opacity-40 group-hover:opacity-90 transition-opacity duration-700 z-10 h-full w-full flex flex-col gap-5 justify-center items-center">
            <p className="text-3xl font-medium text-center md:text-5xl mix-blend-difference group-hover:text-purpple">
              {t('maps.ExploreCTA')}
            </p>
          </div>
          <Image
            src="/assets/heros/maphero2.jpg"
            alt="hero"
            placeholder="blur"
            blurDataURL={cover}
            quality={90}
            priority
            width={500}
            height={500}
            className="rounded-3xl h-full w-full object-cover object-left"
            sizes="100vw"
          />
        </Link>
      </div>
    </div>
  );
};

export default AppHeroNew;
