'use client';

import { useEffect, useState } from 'react';

import { SearchIcon } from '@heroicons/react/outline';
import { LocationMarkerIcon } from '@heroicons/react/outline';
import { PencilIcon, UserIcon } from '@heroicons/react/solid';
import { CalendarDaysIcon } from 'lucide-react';
import Image from 'next/image';
import { useRouter, useSearchParams } from 'next/navigation';
import { useLocale, useTranslations } from 'next-intl';

import { goToSearchKeepingState } from 'hooks/querySearch';
import {
  useDestinationName,
  useOriginName,
  useCheckIn,
  useGuestAdults,
  useGuestChildren,
  useGuestInfants,
  useGuestYouths,
  useGuestSeniors,
} from 'hooks/useQuerySearch';
import { IExploreNearby } from 'typings';
import { formatCheckDate } from 'utils';

import AppSearchOption from './AppSearchOption';
import { Badge } from '../ui/badge';
import { Button } from '../ui/button';
import { FocusField } from './FocusField';

interface Props {
  exploreNearby: IExploreNearby[];
}

const SearchWidget = ({ exploreNearby }: Props) => {
  const locale = useLocale();
  const router = useRouter();
  const searchParams = useSearchParams();

  const [originName] = useOriginName();
  const [destinationName] = useDestinationName();

  const [checkIn] = useCheckIn();

  const [guestAdults] = useGuestAdults();
  const [guestChildren] = useGuestChildren();
  const [guestInfants] = useGuestInfants();
  const [guestYouths] = useGuestYouths();
  const [guestSeniors] = useGuestSeniors();

  const t = useTranslations('lobby');
  const h = useTranslations('Header');

  const [focusField, setFocusField] = useState<FocusField>(FocusField.None);
  const [originImg, setOriginImg] = useState<string | null>(null);
  const [destinationImg, setDestinationImg] = useState<string | null>(null);

  const handleOnSubmit = async (e) => {
    e.preventDefault();

    await goToSearchKeepingState(router, searchParams);
  };

  useEffect(() => {
    if (originName) {
      const originIndex = exploreNearby.findIndex((item) => item.name === originName);
      setOriginImg(exploreNearby[originIndex]?.img);
    } else {
      setOriginImg(null);
    }
  }, [exploreNearby, originName]);

  useEffect(() => {
    if (destinationName) {
      const destinationIndex = exploreNearby.findIndex(
        (item) => item.name === destinationName
      );
      setDestinationImg(exploreNearby[destinationIndex]?.img);
    } else {
      setDestinationImg(null);
    }
  }, [destinationName, exploreNearby]);

  return (
    <div className="FrontPage-children w-fit mx-auto md:mr-auto md:ml-0 border-2 border-opacity-15 border-slate-600 rounded-[38px] p-3.5">
      <form id="form-home-search" method="post" action="/" onSubmit={handleOnSubmit}>
        <div
          id="home-search"
          className="Start theme-reset relative flex w-full whitespace-nowrap rounded-3xl bg-white text-left shadow-arround"
          data-nanocomponent="ncid-75a5"
          data-onloadid4z77gu34egr="o1"
        >
          <div
            className={`Start-panel Start-panel--summary max-w-96 p-[1.1rem] md:p-6 ${
              focusField == FocusField.None ? 'block' : 'hidden'
            }`}
          >
            <div className="flex-start hidden w-full flex-col">
              <div className="flex flex-row items-center group cursor-default relative gap-x-2">
                <h1 className="overflow-auto whitespace-normal text-left text-xl font-bold md:mt-2.5 md:text-2xl">
                  {t('title')}
                </h1>
                <Image
                  alt="loco-icon"
                  src="/assets/icons/loco.svg"
                  width={40}
                  height={40}
                  className="h-9 w-9 group-hover:rotate-6 transition-transform"
                />
                <Badge
                  className="hidden group-hover:block absolute -top-3 -right-3"
                  variant="secondary"
                >
                  Tchou Tchou !
                </Badge>
              </div>
              <h2 className="mt-2 mb-4 text-left font-normal text-slate-400 md:mb-5">
                {t('subtitle')}
              </h2>
            </div>

            <div className="flex flex-row items-center justify-between gap-x-2">
              <div className="Start-location border border-slate-200 relative flex w-full items-center text-left rounded-xl bg-secondary hover:bg-secondary/80 p-0.5 outline-none">
                <div className="Start-details grow flex flex-row items-center gap-4 overflow-hidden whitespace-nowrap">
                  <div className="Start-thumbnail relative flex h-10 w-10 shrink-0 items-center justify-center bg-muted-foreground sm:h-12 sm:w-12 rounded-[0.65rem]">
                    {originName ? (
                      originImg ? (
                        <Image
                          className="Start-img h-full w-full rounded-[0.65rem]"
                          sizes="56px"
                          width="40"
                          height="40"
                          alt=""
                          src={originImg}
                        />
                      ) : (
                        <LocationMarkerIcon className="h-5 w-5 text-white" />
                      )
                    ) : (
                      <SearchIcon className="h-5 w-5 text-white" />
                    )}
                  </div>
                  <span className="Start-output flex w-full font-medium overflow-hidden overflow-ellipsis whitespace-nowrap text-left text-lg">
                    <span className="u-textTruncate">
                      {' '}
                      {originName ?? h('search.from.widgetplaceholder')}
                    </span>
                  </span>
                </div>
                <Button
                  name="panel"
                  type="button"
                  value="origin"
                  size="icon"
                  variant="ghost"
                  onClick={() => {
                    setFocusField(FocusField.Origin);
                  }}
                  className="Button Button--collapse Button--xs before:absolute before:top-0 before:left-0 before:h-full before:w-full before:rounded-2xl max-sm:h-7 max-sm:w-7"
                >
                  <span className="Start-edit flex items-center text-muted-foreground">
                    <PencilIcon className="h-3.5 w-3.5" />
                  </span>
                </Button>
              </div>
            </div>

            <svg
              width="3"
              height="10"
              fill="none"
              role="presentation"
              className="Start-divider my-[0.3rem] ml-5 md:ml-6"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.5.25a.75.75 0 01.75.75v.048a.75.75 0 11-1.5 0V1A.75.75 0 011.5.25zm0 3.904a.75.75 0 01.75.75v.097a.75.75 0 01-1.5 0v-.097a.75.75 0 01.75-.75zm0 3.953a.75.75 0 01.75.75v.048a.75.75 0 11-1.5 0v-.048a.75.75 0 01.75-.75z"
                fill="currentColor"
              ></path>
            </svg>

            <div className="flex flex-row items-center justify-between gap-x-2">
              <div className="Start-location border border-slate-200 relative flex w-full items-center text-left rounded-xl bg-secondary hover:bg-secondary/80 p-0.5 outline-none">
                <div className="Start-thumbnail relative mr-3 flex h-10 w-10 shrink-0 items-center justify-center bg-muted-foreground sm:h-12 sm:w-12 rounded-[0.65rem]">
                  {destinationName ? (
                    destinationImg ? (
                      <Image
                        className="Start-img h-full w-full rounded-[0.65rem]"
                        sizes="56px"
                        width="40"
                        height="40"
                        alt=""
                        src={destinationImg}
                      />
                    ) : (
                      <LocationMarkerIcon className="h-5 w-5 text-white" />
                    )
                  ) : (
                    <SearchIcon className="h-5 w-5 text-white" />
                  )}{' '}
                </div>
                <div className="Start-details grow overflow-hidden whitespace-nowrap">
                  <span className="Start-output flex w-full font-medium overflow-hidden whitespace-nowrap text-left text-lg">
                    {' '}
                    {destinationName ? (
                      destinationName
                    ) : (
                      <p className="text-gray-600"> {h('search.to.widgetplaceholder')}</p>
                    )}
                  </span>
                </div>

                <Button
                  name="panel"
                  value="destination"
                  size="icon"
                  type="button"
                  variant="ghost"
                  onClick={() => {
                    setFocusField(FocusField.Destination);
                  }}
                  className="Button Button--collapse Button--cover Button--adapt Button--muted Button--xs before:absolute before:top-0 before:left-0 before:h-full before:w-full max-sm:h-7 max-sm:w-7"
                >
                  <span className="Start-edit flex items-center text-muted-foreground">
                    {' '}
                    <PencilIcon className="h-3.5 w-3.5" />
                  </span>
                </Button>
              </div>
            </div>

            {originName && destinationName && originName == destinationName && (
              //Display a banner cause impossible to have the same origin and destination
              <div className="Start-location relative flex w-full items-center text-left text-sm">
                <span className="mt-1 rounded-md border-l-4 border-destructive bg-destructive/20 py-1 px-2">
                  {h('search.to.sameDepartureError')}
                </span>
              </div>
            )}

            <ul className="Start-actions mt-6 flex items-center justify-center flex-wrap gap-y-3 gap-x-3">
              <li className="Start-action last:mr-0 last:ml-auto">
                <Button
                  name="panel"
                  value="passengers"
                  type="button"
                  variant="outline"
                  className="shadow-sm"
                  size="selector"
                  onClick={() => {
                    setFocusField(FocusField.Passengers);
                  }}
                >
                  <UserIcon className="h-[1.1rem] w-[1.1rem]" />
                  <span className="Start-hint ml-1.5">
                    {' '}
                    {guestAdults +
                      guestChildren +
                      guestYouths +
                      guestSeniors +
                      guestInfants}{' '}
                    {h('search.travelers.suffix')}
                  </span>
                </Button>
              </li>
              <li className="Start-action last:mr-0 last:ml-auto">
                <Button
                  name="panel"
                  variant="outline"
                  value="ddate"
                  type="button"
                  size="selector"
                  className="shadow-sm"
                  onClick={() => {
                    setFocusField(FocusField.Date);
                  }}
                >
                  <CalendarDaysIcon className="mr-2 h-4 w-4" />
                  {formatCheckDate(checkIn, locale)}
                </Button>
              </li>
              <li className="Start-action last:mr-0 last:ml-auto w-full">
                <Button
                  id="button-gtm-search-submit"
                  type="submit"
                  disabled={originName == destinationName}
                  size="xl"
                  className="Button Button--collapse Button--sm h-auto py-0 px-0 shadow-md w-full bg-orange hover:bg-orange/80"
                >
                  <span
                    id="gtm-search-lp-hero"
                    className="Start-submit inline-flex items-center justify-center py-3 px-4 text-lg md:px-9 md:py-4"
                  >
                    {' '}
                    <SearchIcon className="h-4 w-4 md:h-5 md:w-5" />
                    <span
                      id="gtm-search-lp-hero-span"
                      className="Start-hintAlt ml-2 text-base"
                    >
                      {h('search.button')}
                    </span>
                  </span>
                </Button>
              </li>
            </ul>
          </div>

          <AppSearchOption
            exploreNearby={exploreNearby}
            onClose={() => {
              setFocusField(FocusField.None);
            }}
            field={focusField}
          />
        </div>
      </form>
    </div>
  );
};

export default SearchWidget;
